
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexeH_45knvsPxuzKCtuhxbzmq3A52gxj9sNHQK_MhXjK724Meta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as Invoicesi1Gm_45ijOAZBNPC77n1iEafdXHEAYj9CbS6hgvlb7RX4Meta } from "/opt/atlassian/pipelines/agent/build/pages/Invoices.vue?macro=true";
import { default as indexoWkl7Pjmmmjtdi8mamd9Tj_Yu83vvFR4bstZWuT4Uf8Meta } from "/opt/atlassian/pipelines/agent/build/pages/login/index.vue?macro=true";
import { default as crezco_45loadingklUdWKb7tLNY5RpuAINqbLBUrDfCh_45EF3FfrYbyhr9EMeta } from "/opt/atlassian/pipelines/agent/build/pages/crezco-loading.vue?macro=true";
import { default as index4wh7UvaOC5QAnbJ6t_45fkFqkB5o4MVhdKqTcMw_xccfgMeta } from "/opt/atlassian/pipelines/agent/build/pages/change/email/index.vue?macro=true";
import { default as indexcmv1lCbtKQziK_lJjeBM4TKuMPE_45iL3jawk_45_TgoFD4Meta } from "/opt/atlassian/pipelines/agent/build/pages/validate/email/index.vue?macro=true";
import { default as indexTPwp6Bqj6ztpzgLgHoZEWY57Owusf5gix0R_45Dn8547QMeta } from "/opt/atlassian/pipelines/agent/build/pages/validate/phone/index.vue?macro=true";
import { default as indexi4NiqGdPz_rLMXayogxVJXKZekq_CoOojgVaeAc5wP8Meta } from "/opt/atlassian/pipelines/agent/build/pages/change/password/index.vue?macro=true";
import { default as indexf18g8D5W_45ft6hmuLOVAX1dtKTih2Eh3mCFvSWQebvD8Meta } from "/opt/atlassian/pipelines/agent/build/pages/validate/password/index.vue?macro=true";
import { default as indexgefYBUdEy9ryiELH5MI5CNoTJZ2itMSHGYVfgSyXYigMeta } from "/opt/atlassian/pipelines/agent/build/pages/widget/[businessCode]/[group]/index.vue?macro=true";
import { default as indexcqr_7O6BphvCT4SBvogQAK10Of1mvpIFZ3d7bZiHN08Meta } from "/opt/atlassian/pipelines/agent/build/pages/widget/chat/[businessCode]/[group]/index.vue?macro=true";
import { default as index3uxjoYJmW31DVhvjzhm3nM95G2Lx_Z8MGgE7OvBALPMMeta } from "/opt/atlassian/pipelines/agent/build/pages/[type]/[businessCode]/[invoiceCode]/index.vue?macro=true";
import { default as indexYTPgDfR_45S_45v4J7S0G0hLW_45nr9DG8928RWVz_45VE_45hoskMeta } from "/opt/atlassian/pipelines/agent/build/pages/widget/payments/[businessCode]/[group]/index.vue?macro=true";
import { default as indexZUpmW4TXlwqr_459fYCj8W0vL2MtzxefBcW6BeieARmBcMeta } from "/opt/atlassian/pipelines/agent/build/pages/widget/documents/[businessCode]/[group]/index.vue?macro=true";
import { default as indexuVTT_4wstnyyEto_FemFwSluT_45xXSFZgPeqo78qg7HMMeta } from "/opt/atlassian/pipelines/agent/build/pages/widget/conversations/[businessCode]/[group]/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  },
  {
    name: "Invoices",
    path: "/Invoices",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/Invoices.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/login/index.vue")
  },
  {
    name: "crezco-loading",
    path: "/crezco-loading",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/crezco-loading.vue")
  },
  {
    name: "change-email",
    path: "/change/email",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/change/email/index.vue")
  },
  {
    name: "validate-email",
    path: "/validate/email",
    meta: indexcmv1lCbtKQziK_lJjeBM4TKuMPE_45iL3jawk_45_TgoFD4Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/validate/email/index.vue")
  },
  {
    name: "validate-phone",
    path: "/validate/phone",
    meta: indexTPwp6Bqj6ztpzgLgHoZEWY57Owusf5gix0R_45Dn8547QMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/validate/phone/index.vue")
  },
  {
    name: "change-password",
    path: "/change/password",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/change/password/index.vue")
  },
  {
    name: "validate-password",
    path: "/validate/password",
    meta: indexf18g8D5W_45ft6hmuLOVAX1dtKTih2Eh3mCFvSWQebvD8Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/validate/password/index.vue")
  },
  {
    name: "widget-businessCode-group",
    path: "/widget/:businessCode()/:group()",
    meta: indexgefYBUdEy9ryiELH5MI5CNoTJZ2itMSHGYVfgSyXYigMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/widget/[businessCode]/[group]/index.vue")
  },
  {
    name: "widget-chat-businessCode-group",
    path: "/widget/chat/:businessCode()/:group()",
    meta: indexcqr_7O6BphvCT4SBvogQAK10Of1mvpIFZ3d7bZiHN08Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/widget/chat/[businessCode]/[group]/index.vue")
  },
  {
    name: "invoice-page",
    path: "/:type()/:businessCode()/:invoiceCode()",
    meta: index3uxjoYJmW31DVhvjzhm3nM95G2Lx_Z8MGgE7OvBALPMMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[type]/[businessCode]/[invoiceCode]/index.vue")
  },
  {
    name: "widget-payments-businessCode-group",
    path: "/widget/payments/:businessCode()/:group()",
    meta: indexYTPgDfR_45S_45v4J7S0G0hLW_45nr9DG8928RWVz_45VE_45hoskMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/widget/payments/[businessCode]/[group]/index.vue")
  },
  {
    name: "widget-documents-businessCode-group",
    path: "/widget/documents/:businessCode()/:group()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/widget/documents/[businessCode]/[group]/index.vue")
  },
  {
    name: "widget-conversations-businessCode-group",
    path: "/widget/conversations/:businessCode()/:group()",
    meta: indexuVTT_4wstnyyEto_FemFwSluT_45xXSFZgPeqo78qg7HMMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/widget/conversations/[businessCode]/[group]/index.vue")
  }
]